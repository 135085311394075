import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Animations from '../Animations'

const WhiteBackground = styled.div`
background-color: white;
`
const Image = styled.img`
max-width:300px;
`
const ServiceBoxDividerStyle = styled.div`
margin-top: 30px;
@media (min-width: 300px) {
    width: 229px;
}
height: 9px;
background-color: var(--primary);
border-radius: 30px;
opacity: 0;
`

export const Services = (props) => {
  const { Content } = props
  let startOfServices = React.useRef(null)

  useEffect(() => {
    Animations('.services', startOfServices.current)
  }, [])

  return (
    <React.Fragment >
      <WhiteBackground>
        <Container>
          <Row ref={startOfServices}>
            <Col xs={12} md={6} className="mt-5 pr-3 d-flex flex-column align-items-center align-items-md-start text-center text-md-left">
              <div className="">
                <h1 className="sec0 my-3">{Content.ServicesTitle}</h1>
                <div className="services"> {Content.ServicesParagraph}</div>
              </div>
              <div className="services">
                <Image className="sec2 my-5" src={Content.Service1Icon} alt={Content.Services1AltText} />
                <h4 className="sec2">{Content.Services1Title}</h4>
                <div className="sec2">{Content.Services1Paragraph}</div>
                <ServiceBoxDividerStyle className="sec2" />
              </div>

            </Col>
            <Col xs={12} md={6} className="mt-5 pl-3 d-flex flex-column align-items-center align-items-md-end text-center text-md-right">
              <div className="services">
                <Image src={Content.Service2Icon} className="my-5 sec1" alt={Content.Services2AltText} />
                <h4 className="my-3 sec1">{Content.Services2Title}</h4>
                <div className="sec1">{Content.Services2Paragraph}</div>
                <ServiceBoxDividerStyle className="my-5 sec1" />
              </div>
              <div className="services">
                <Image src={Content.Service3Icon} className="my-5 sec3" alt={Content.Services3AltText} />
                <h4 className='sec3'>{Content.Services3Title}</h4>
                <div className="sec3">{Content.Services3Paragraph}</div>
                <ServiceBoxDividerStyle className="my-5 sec3" />
              </div>
            </Col>

          </Row>
        </Container>
      </WhiteBackground>
    </React.Fragment>
  )
}

Services.defaultProps = {
  Content: PropTypes.object
}
