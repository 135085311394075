import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Col, Container, Nav, Navbar, Row, Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { CONTACT_US_PHONENUMBER } from '../Content.en'
import Flags from '../Flags'
import { BlueBackgroundHeader, ButtonStyledSecondary, FadedTextH1 } from '../form'
import { langs } from '../Locale'

export const Welcome = props => {
  const { Content, lang } = props

  const BoldNavLink = styled(({ className, children }) => {
    const anchor = children.toLowerCase()
    return <a href={`#${anchor}`} onClick={props.onClickScrollToAnchor(anchor)} className={`${className} nav-link`}>{children}</a>
  })`
    font-weight: bold !important;
    cursor: pointer;
    `

  const StyledDropdownToggle = styled(Dropdown.Toggle)`
    background-color: transparent;
    border-color: transparent;
    `

  useEffect(() => {

  }, [lang])

  return (
    <React.Fragment>
      <BlueBackgroundHeader>
        <Navbar variant="dark" expand="lg">
          <Container className="">
            <Navbar.Brand href="#home"><img src={Content.MPIcon} alt={Content.MPLogoAltText} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end pt-3">
              <Nav>
                <BoldNavLink>{Content.HeaderItem1}</BoldNavLink>
                <BoldNavLink>{Content.HeaderItem2}</BoldNavLink>
                <BoldNavLink>{Content.HeaderItem3}</BoldNavLink>
                {/* <BoldNavLink>{Content.HeaderItem4}</BoldNavLink> */}
                <BoldNavLink>{Content.HeaderItem5}</BoldNavLink>
                <Dropdown>
                  <StyledDropdownToggle variant="secondary">
                    {Flags(lang)}
                  </StyledDropdownToggle>
                  <Dropdown.Menu style={{ minWidth: '7rem' }}>
                    {langs.map(l => <Dropdown.Item key={l.lang} as={Link} to={l.url} style={{ padding: '4px 12px', minwidth: '100px' }}>{Flags(l.lang)} {l.Name}</Dropdown.Item>)}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <Row>
            <Col xs={12} md={6} className="my-5 text-white">
              <h1 className="my-4">{Content.WelcomeTitle}</h1>
              <FadedTextH1 className="mb-4">{Content.WelcomeParagraph}</FadedTextH1>
              <div className="d-flex">
                <ButtonStyledSecondary onClick={() => { document.location.href = `tel:${CONTACT_US_PHONENUMBER}` }} className="ml-1">{Content.WelcomeButton2}</ButtonStyledSecondary>
              </div>
            </Col>
          </Row>
        </Container>
      </BlueBackgroundHeader>
    </React.Fragment>
  )
}

Welcome.defaultProps = {
  Content: PropTypes.object
}
