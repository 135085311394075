import Images from './image_loading/images'
const {
  SiporaLogo,
  HnKLogo,
  SuperrewardsLogo,
  MyStayWellLogo,
  MyPatientLogo,
  MyRamsayLogo,
  AddressrLogo,
  SafeEntryLogo,
  KneeRomLogo,
  RabLogo,
  GsiLogo
} = Images

export const Portfolios = [
  {
    tag: 'SuperRewards',
    altText: 'SuperRewards Logo',
    industry: 'ecommerce',
    link: 'https://super-rewards.com',
    logo: SuperrewardsLogo
  },
  {
    tag: 'SafeEntry',
    altText: 'SafeEntry Logo',
    industry: 'community',
    link: 'https://www.safeentry.com.au',
    logo: SafeEntryLogo
  },
  {
    tag: 'Regional Australia Bank',
    altText: 'Regional Australia Bank Logo',
    industry: 'finance',
    link: 'https://www.regionalaustraliabank.com.au/',
    logo: RabLogo
  },
  {
    tag: 'GlobalSign.in',
    altText: 'Global Signin Logo',
    industry: 'events',
    link: 'https://www.globalsignin.com/',
    logo: GsiLogo
  },
  {
    tag: 'KneeROM',
    altText: 'Knee-ROM',
    industry: 'medical',
    link: 'http://www.knee-rom.com/',
    logo: KneeRomLogo
  },
  {
    tag: 'Addressr',
    altText: 'Addressr Logo',
    industry: 'utility',
    link: 'https://addressr.mountain-pass.com.au',
    logo: AddressrLogo
  },
  {
    tag: 'Sipora',
    link: 'https://www.sipora.com.au',
    altText: 'Sipora Logo',
    industry: 'ecommerce',
    linkApple: 'https://apps.apple.com/au/app/sipora-round-up-shopping/id1452939608',
    linkAnd: 'https://play.google.com/store/apps/details?id=au.com.sipora.mobile',
    logo: SiporaLogo
  },
  {
    tag: 'Handle & Kate',
    altText: 'HandleAndKate Logo',
    industry: 'ecommerce',
    link: 'https://www.handleandkate.com',
    logo: HnKLogo
  },
  {
    tag: 'MyStayWell',
    altText: 'MyStayWell Logo',
    industry: 'medical',
    linkApple: 'https://apps.apple.com/au/app/mystaywell/id1323886099',
    linkAnd: 'https://play.google.com/store/apps/details?id=au.com.ramsayhealth.staywellplan',
    logo: MyStayWellLogo
  },
  {
    tag: 'MyPatient+',
    altText: 'MyPatientPlus Logo',
    industry: 'medical',
    linkApple: 'https://apps.apple.com/au/app/mypatient/id1057766863',
    linkAnd: 'https://play.google.com/store/apps/details?id=au.com.ramsayhealth.mypatientplus',
    logo: MyPatientLogo
  },
  {
    tag: 'MyRamsay',
    altText: 'MyRamsay Logo',
    industry: 'medical',
    linkApple: 'https://apps.apple.com/au/app/myramsay/id1267193820',
    linkAnd: 'https://play.google.com/store/apps/details?id=au.com.ramsayhealth.myramsay',
    logo: MyRamsayLogo
  }
  // {
  //     tag: 'Vangaard',
  //     logo: 'about:blank',
  // },
]
