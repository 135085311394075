import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import posed from 'react-pose'
import styled from 'styled-components'
import { BlueBackground, ButtonStyledSecondary, FadedText, SocialMediaIcon } from '../form'
import ContactForm from './ContactForm'

const GrowingLink = posed.a({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1
  },
  hover: {
    scale: 1.1
  },
  press: {
    scale: 1.1
  }
})

const TightParagraphs = styled.div`
h5 {
    margin-bottom: 15px;
}
p {
    margin: 5px 0px;
    padding: 0;
}
`

export default (props) => {
  const { Content } = props

  const [modalStatus, setModalStatus] = useState('CloseModal')

  const openModal = () => {
    setModalStatus('OpenModal')
  }

  const changeWindowStatus = (s) => {
    setModalStatus(s)
  }

  return (
    <React.Fragment>
      <BlueBackground>
        <Container>
          <Row>
            <Col xs={12} md={6} className="d-flex flex-column my-5">
              <h1 className="text-white mb-3">{Content.ContactTitle}</h1>
              <FadedText className="mb-5">{Content.ContactParagraph}</FadedText>
              <div className="flex-grow-1"><ButtonStyledSecondary onClick={() => openModal()}>{Content.ContactMessageButtonText}</ButtonStyledSecondary></div>
              <div className="d-flex mt-5 justify-content-center">
                <GrowingLink href="https://fb.me/mountainpass" target="_blank" rel="noopener noreferrer">
                  <SocialMediaIcon className="mx-2" icon={Content.ContactSocialMedia[0].Icon} alt={Content.ContactSocialMedia[0].AltText} />
                </GrowingLink>
                <GrowingLink href="https://au.linkedin.com/company/mountainpass" target="_blank" rel="noopener noreferrer">
                  <SocialMediaIcon className="mx-2" icon={Content.ContactSocialMedia[1].Icon} alt={Content.ContactSocialMedia[1].AltText} />
                </GrowingLink>

              </div>
            </Col>
            <Col xs={12} md={6} className="my-5 d-flex flex-column ">
              <TightParagraphs className="mb-5 my-5 ml-auto text-right text-nowrap text-white">
                <Content.ContactBusinessLocation />
                <Content.ContactBusinessEmail />
                <Content.ContactBusinessMob />
                <Content.ContactBusinessRights />
              </TightParagraphs>
              <img src={Content.MPIcon} className="ml-auto mt-auto" alt={Content.MPLogoAltText} />
            </Col>
          </Row>
        </Container>
      </BlueBackground>
      <ContactForm content={Content.ContactForm} changeStatus={changeWindowStatus} status={modalStatus} />
    </React.Fragment>
  )
}
