import { gsap, ScrollTrigger } from 'gsap/all'

export default (selector, trigger) => {
  gsap.registerPlugin(ScrollTrigger)

  const animFrom = { opacity: 0, y: 200 }
  const animTo = { opacity: 1, y: 0, ease: 'power3.out', duration: 1, stagger: 0.15 }

  return (gsap.timeline({
    scrollTrigger: {
      trigger: trigger,
      start: 'top 60%',
      end: 'bottom top',
      toggleActions: 'restart none none reverse'
    }
  })
  // alternatively, we can use an array of refs for first param...
    .fromTo(selector, animFrom, animTo)

  )
}
