import React from 'react'
import { Form, Container, Col, Button } from 'react-bootstrap'
import ProgressBar from 'react-bootstrap/ProgressBar'
import styled from 'styled-components'
import posed from 'react-pose'
import axios from 'axios'

const Modal = styled.div`
background: rgba(0, 50, 100, 0.97);
backdrop-filter: blur(8px);
z-index: 999;
top: 0;
position: fixed;
height: 100%;
width: 100%;
z-index: 2147483648; // higher than drift

`

const Label = styled(Form.Label)`
    font-size: 28px;
    font-weight:bold;
    text-align: left;
`

const Text = styled(Form.Text)`
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    padding-bottom: 5px;
`

const CloseButton = styled(Button)`
top: 15px;
left: 15px;
width: 56px;
height: 56px;
padding: 12px;
position: fixed;
color: white !important;
background-color: transparent !important;
font-size: 32px !important;
line-height: 32px !important;
vertical-align: middle;
text-decoration: none !important;
border: 1px solid transparent !important;
background-color: rgba(255,255,255,0.3) !important;
&:hover {
    background-color: rgba(255,255,255,0.3) !important;
}`

const ChevronRight = styled(Button)`
top: 15px;
right: 15px;
min-width: 56px;
min-height: 56px;
padding: 12px;
position: fixed;
color: white !important;
background-color: transparent !important;
font-size: 32px !important;
line-height: 32px !important;
vertical-align: middle;
text-decoration: none !important;
border: 1px solid transparent !important;
background-color: rgba(255,255,255,0.3) !important;
&:hover {
    background-color: rgba(255,255,255,0.3) !important;
}
`

const ProgressBarContainer = styled(({ className, ...props }) => {
  return <div className={`${className} d-none d-lg-flex justify-content-center`} {...props}></div>
})`
position: fixed;
width: 100%;
bottom: 2%;
padding: 4px;
`

const Progress = styled(ProgressBar)`
width: 50vw;
height: 24px !important;
border: 1px solid white;
background-color: transparent !important;
background-image: none !important;
`
const AnimatedModal = posed(Modal)({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})
class ContactForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      status: 0,
      name: '',
      email: '',
      message: '',
      modalStatus: 'CloseModal',
      progress: 0,
      queries: 3,
      nextButton: <span>&#8594;</span>,
      blankInput: false,
      blankInputMessage: 'Please do not leave blank.',
      Content: {},
      prefillMessage: ''
    }
    this.submitHandler = this.submitHandler.bind(this)
    this.logKey = this.logKey.bind(this)
    this.resetStates = this.resetStates.bind(this)
    this.checkEmptyInput = this.checkEmptyInput.bind(this)
  }

  checkEmptyInput (input) {
    if (input.trim(' ').length > 0) {
      this.setState({ blankInput: false })
      return true
    } else {
      this.setState({ blankInput: true })
      return false
    }
  }

  submitHandler (dir) {
    // e.preventDefault()

    const status = this.state.status + (dir === 'prev' ? -1 : dir === 'next' || dir === 'enter' ? 1 : 0)
    // console.log({ dir, status })

    switch (status) {
      case 0:
        setTimeout(() => {
          setTimeout(() => this.nameInput.focus(), 100)
          this.setState({ status, progress: 0 })
        }, 100)
        break

      case 1:
        if (!this.checkEmptyInput(this.state.name)) {
          // ?
        } else {
          setTimeout(() => {
            setTimeout(() => this.emailInput.focus(), 100)
            this.setState({ status, progress: 33 })
          }, 100)
        }
        break

      case 2:
        if (!this.checkEmptyInput(this.state.email)) {
          // ?
        } else {
          setTimeout(() => {
            setTimeout(() => this.messageInput.focus(), 100)
            this.setState({ status, progress: 66, nextButton: <span>&#10003;</span> })
          }, 100)
        }
        break

      case 3:

        this.setState({ status, progress: 100, nextButton: null })
        break

      default:
        if (!this.checkEmptyInput(this.state.message)) {
          // ?
        } else {
          this.props.changeStatus('CloseModal')
          let { name = '', email = '', message = '' } = this.state

          if ([name, email, message].some(x => typeof x === 'string' && x.trim().length > 0)) {
            axios({
              method: 'post',
              url: window.location.href + 'api/slack',
              // url: 'http://localhost:3333/api/slack',
              data: { 'text': `*Name:* ${name} \n *Email:* ${email}\n *Message:* ${message}` }
            })
            // .then(() => console.log('success'))
            // .catch(err => console.log('fail', err))
          } else {
            console.log(`Abandoning blank message.`)
          }
          this.resetStates()
        }
        break
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.logKey)
    this.setState({ Content: this.props.content })
  }

  logKey (e, inputEvent) {
    if (this.props.status === 'OpenModal') {
      if (e.shiftKey) {
        switch (e.code) {
          case 'ArrowLeft':
            this.submitHandler('prev')
            break

          case 'ArrowRight':
            this.submitHandler('next')
            break

          default:
            break
        }
      }
      if (e.key === 'Enter') {
        this.submitHandler('enter')
      }
      if (e.key === 'Escape') {
        this.resetStates()
      }
    }
  }

  shouldComponentUpdate (nextProps = {}, nextState) {
    const { status } = nextProps

    if (this.state.modalStatus !== nextProps.status) {
      this.setState({ modalStatus: nextProps.status })

      if (status === 'OpenModal') {
        setTimeout(() => this.nameInput.focus(), 300)
      }
      if (this.props.prefillMessage !== '') {
        this.setState({ message: this.props.prefillMessage })
      }
    }
    return true
  }

  resetStates () {
    this.props.changeStatus('CloseModal')

    this.setState({
      status: 0,
      name: '',
      email: '',
      message: '',
      modalStatus: 'CloseModal',
      progress: 0,
      nextButton: <span>&#8594;</span>,
      blankInput: false,
      prefillMessage: ''
    })
  }

  render () {
    const { Content } = this.state

    return (
      <AnimatedModal className={` abc ${this.state.modalStatus === 'OpenModal' ? 'd-flex' : 'd-none'}  align-items-center`}
        pose={this.state.modalStatus === 'OpenModal' ? 'enter' : 'exit'}>
        <CloseButton className="text-center btn-link d-flex justify-content-center align-items-center " onClick={() => this.resetStates()}>&times;</CloseButton>
        {/* <ChevronLeft className="text-center btn-link d-flex justify-content-center align-items-center " onClick={() => this.submitHandler("prev")}>&#8592;</ChevronLeft> */}
        <ChevronRight className={`${this.state.status !== 3 ? 'd-flex ' : 'd-none'} text-center btn-link justify-content-center align-items-center `} onClick={() => this.submitHandler('next')}>{this.state.nextButton}</ChevronRight>
        <ProgressBarContainer>
          <Progress now={this.state.progress} label={`${this.state.progress}%`} />
        </ProgressBarContainer>

        <Container>
          <Col lg={12}>
            <Form className="d-flex flex-column">
              <Form.Group style={{ width: '100%' }} className={`${this.state.status === 0 ? 'd-block' : 'd-none'} text-white justify-content-center align-items-center p-3`} controlId="formBasicEmail">
                <Label>{Content.q1_title}</Label>
                <Text>
                  {Content.q1}
                </Text>
                <Form.Control className=" form-control-lg" type="text" placeholder={Content.q1_placeholder}
                  ref={(ref) => { this.nameInput = ref }}
                  value={this.state.name}
                  onChange={v => { this.setState({ name: v.target.value }) }}
                />
                {this.state.blankInput && <Text>
                  {Content.validation}
                </Text>}
              </Form.Group>
              <Form.Group style={{ width: '100%' }} className={`${this.state.status === 1 ? 'd-block' : 'd-none'} text-white justify-content-center align-items-center p-3`} controlId="formBasicEmail">
                <Label>{Content.q2_title}</Label>
                <Text >
                  {Content.q2}
                </Text>
                <Form.Control type="email" className=" form-control-lg" placeholder={Content.q2_placeholder}
                  ref={(ref) => { this.emailInput = ref }}
                  value={this.state.email}
                  onChange={v => { this.setState({ email: v.target.value }) }} />
                {this.state.blankInput && <Text className="">
                  {Content.validation}
                </Text>}
              </Form.Group>
              <Form.Group style={{ width: '100%' }} className={`${this.state.status === 2 ? 'd-block' : 'd-none'} text-white justify-content-center align-items-center p-3`} controlId="formBasicEmail">
                <Label>{Content.q3_title}</Label>
                <Text>
                  {Content.q3}
                </Text>
                <Form.Control type="text" className=" form-control-lg" placeholder={Content.q3_placeholder}
                  ref={(ref) => { this.messageInput = ref }}
                  value={this.state.message}
                  onChange={v => { this.setState({ message: v.target.value }) }} />
                {this.state.blankInput && <Text className="">
                  {Content.validation}
                </Text>}
              </Form.Group>
              <Form.Group style={{ width: '100%' }} className={`${this.state.status === 3 ? 'd-block' : 'd-none'} text-white justify-content-center align-items-center p-3`} controlId="formBasicEmail">
                {/* <Label>{Content.q3_title}</Label> */}
                <Text>
                  {Content.ConfirmSubmission}
                </Text>
              </Form.Group>
            </Form>
          </Col>
        </Container>
      </AnimatedModal>
    )
  }
}

export default ContactForm
