import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import Animations from '../Animations'
import { TestimonialsBackground } from '../form'

const LogoContainer = styled.div`
min-width: 80%;
img{
    border-radius: 10px;
}
`

const TestimonialBody = styled(Row)`
    background-color: white;
    text-align: left;
    width: 70%;
    border-radius: 10px 10px 0px 0px;
    min-height: 200px; 
    min-width: 300px;
`

const TestimonialName = styled(Col)`
border-left: 1px solid black;
font-size: 12px;
`

const TestimonialNameContent = styled(Row)`
width: 80%;
`
const TestimonialFooter = styled(Row)`
    background-color: white;
    text-align: left;
    width: 70%;
    border-radius: 0px 0px 10px 10px;
    min-height: 50px; 
    min-width: 300px;
    padding-bottom: 1rem;
    position: relative;
    top: -1px;
`
const SpeechBubbleTail = styled.div`

border-right: 15px solid white;
border-bottom: 20px solid transparent;
border-top: 20px solid white;
border-left: 15px solid transparent;
width: 0px;
height: 0px;
position: relative;
top: -2px;
`

const StyledAndIcon = styled(FontAwesomeIcon)`
font-size: 40px;
color: var(--primary) !important;
position: relative;
top: 2px;
padding: 2px 0px;
`

export default (props) => {
  const { Content } = props
  let startOfTestimonials = React.useRef(null)

  useEffect(() => {
    Animations('.testimonials', startOfTestimonials.current)
  }, [])

  return (
    <React.Fragment>
      <TestimonialsBackground>
        <Container>
          <Row>
            <Col ref={startOfTestimonials}>
              <h1 className="my-3 text-white">{Content.TestimonialsTitle}</h1>
              {Content.TestimonialsSummary.length > 0 &&
                                <Col xs={12} md={6} className="my-5 text-white pl-0">
                                  <div>{Content.TestimonialsSummary}</div>
                                </Col>
              }
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row>
            {Content.Testimonials.map((t, i) => {
              let Logo = t.logo
              return (
                <Col s={4} xs={12} md={4} lg={4} xl={4} key={i} className={`testimonials t${i} my-5 d-flex flex-column align-items-center`}>
                  <TestimonialBody className='mt-2 p-4 shadow d-flex flex-column'>
                    <div className="d-flex justify-content-center pb-3">
                      <StyledAndIcon icon={faQuoteRight} />
                    </div>
                    <div className='text-center py-3'>{t.content}</div>
                  </TestimonialBody>
                  <TestimonialFooter className="pb-4">
                    <Col className="d-flex justify-content-center align-items-center px-2 flex-grow-1">
                      <LogoContainer>
                        {Logo && <Logo />}
                      </LogoContainer>
                    </Col>
                    <TestimonialName className=" py-3 d-flex flex-column justify-content-center align-items-center">
                      <TestimonialNameContent className="d-flex-row"><b>{t.title}</b></TestimonialNameContent>
                      <TestimonialNameContent className='d-flex-row mb-4'>{t.role}</TestimonialNameContent>
                    </TestimonialName>
                  </TestimonialFooter>
                  <SpeechBubbleTail />
                </Col>
              )
            })}
          </Row>
        </Container>
      </TestimonialsBackground>
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: ""}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
