import React from 'react'
import styled from 'styled-components'
import auFlag from '../../static/images/flags/au.svg'
import frFlag from '../../static/images/flags/fr.svg'
import thFlag from '../../static/images/flags/th.svg'

const flagByLang = {
  en: auFlag,
  fr: frFlag,
  th: thFlag
}
const StyledFlag = styled.img`
width: 20px;
margin-right: 5px;
`

export default (lang) => (<StyledFlag src={flagByLang[lang]} />)
