import React from 'react'
import { animateScroll as scroll } from 'react-scroll'

import SEO from '../components/seo'
import Contact from '../components/HomeSections/Contact'
import { Welcome } from '../components/HomeSections/Welcome'
import { Services } from '../components/HomeSections/Services'
import Testimonials from '../components/HomeSections/Testimonials'
import { Portfolio } from '../components/HomeSections/Portfolio'
// import Packages from '../components/HomeSections/Packages'
import { determineLanguage, determineLocalisation, DEFAULT_LANG, DEFAULT_LOCALE } from '../components/Locale'

const IndexPage = (props) => {
  const [lang, setLang] = React.useState(DEFAULT_LANG)
  const [locale, setLocale] = React.useState(DEFAULT_LOCALE)

  React.useEffect(() => {
    let { pageContext: { lang: urlLang = null } = {} } = props
    setLanguage(urlLang)
  }, [props])

  const onClickScrollToAnchor = anchor => e => {
    e.preventDefault()
    e.stopPropagation()

    if (lang === 'en') {
      anchor = anchor.toUpperCase()
    }
    console.log('anchor', anchor)
    const anchorRef = refs[anchor].current
    // get the height from the top of the window... then scroll to it...
    const distance = typeof window !== 'undefined' ? (window.pageYOffset + anchorRef.getBoundingClientRect().top) : 0
    scroll.scrollTo(distance)
  }

  const setLanguage = (l) => {
    const lang = determineLanguage(l)
    const locale = determineLocalisation(lang)
    console.debug(`using lang - '${lang}'`)
    setLang(lang)
    setLocale(locale)
  }

  const refs = {}
  refs[locale.HeaderItem0] = React.useRef(null)
  refs[locale.HeaderItem1] = React.useRef(null)
  refs[locale.HeaderItem2] = React.useRef(null)
  refs[locale.HeaderItem3] = React.useRef(null)
  refs[locale.HeaderItem4] = React.useRef(null)
  refs[locale.HeaderItem5] = React.useRef(null)

  // console.log("determined locale: ", locale)
  return (
    <>
      <SEO lang={lang} Content={locale} />
      <a ref={refs.welcome} name="welcome" href="/#" aria-label="welcome anchor"> </a>
      <Welcome onClickScrollToAnchor={onClickScrollToAnchor} Content={locale} lang={lang} />
      <a ref={refs[locale.HeaderItem1]} name="services" href="/#" aria-label="services anchor"> </a>
      <Services Content={locale} />
      <a ref={refs[locale.HeaderItem2]} name="testimonials" href="/#" aria-label="testimonials anchor"> </a>
      <Testimonials Content={locale} />
      <a ref={refs[locale.HeaderItem3]} name="portfolio" href="/#" aria-label="portfolio anchor" > </a>
      <Portfolio Content={locale} />
      {/* <a ref={refs[locale.HeaderItem4]} name="packages" href="/#" aria-label="packages anchor" > </a>
      <Packages Content={locale.Packages} FormContent={locale.ContactForm} /> */}
      <a ref={refs[locale.HeaderItem5]} name="contact" href="/#" aria-label="contact anchor"> </a>
      <Contact Content={locale} />
    </>
  )
}

export default IndexPage
