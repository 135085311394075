import React from 'react'
import Service2Icon from './Gfx/Service_icon_0.svg'
import Service1Icon from './Gfx/Service_icon_1.svg'
import Service3Icon from './Gfx/Service_icon_2.svg'
import PortfolioIMG from './Gfx/Portfolio_example.svg'
import ContactSubmitIcon from './Gfx/ContactButton.svg'
import MPIcon from './Gfx/MP_icon.svg'
import styled from 'styled-components'
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { Portfolios } from './Content_Portfolio'
import Images from './image_loading/images'

const SmallerText = styled.p`
    Font-Size: 14px;
    color: 'blue';
`
const StyledLinks = styled.a` 
color: var(--primary);
&:hover {
    text-decoration: underline;
    color: var(--primary);
}
`
const SiteMeta = {
  title: `Mountain Pass | Web and Mobile Development Solutions`,
  description: 'We specialise in building bespoke Web and Mobile Development Solutions for your business. With over 30 years of integration experience, we can connect your product to any third party system. No matter what stage of product development you\'re at, we can provide technical advice, mentorship and solutions - so you can concentrate on running your business.',
  author: `info@mountain-pass.com.au`,
  url: `https://www.mountain-pass.com.au`,
  metaKeywords: 'Mobile app development, iPhone app development, Android app development, iPad App development, iOS App Development, custom web development, website development, reactjs web development, react native web development, react developers, integration, software, development, solutions, mountainpass, mountain, pass, sydney, australia, barangaroo'
}

export const CONTACT_US_PHONENUMBER = '+61467546656'
export const CONTACT_US_PHONENUMBER_LINK = <StyledLinks href={`tel:${CONTACT_US_PHONENUMBER}`}>0467 546 656</StyledLinks>

const Name = 'English'

const HeaderItem0 = 'WELCOME'
const HeaderItem1 = 'SERVICES'
const HeaderItem2 = 'TESTIMONIALS'
const HeaderItem3 = 'PORTFOLIO'
const HeaderItem4 = 'PACKAGES'
const HeaderItem5 = 'CONTACT'

const WelcomeTitle = `Mountain Pass`
const WelcomeParagraph = `Web and Mobile Development Solutions`
// const WelcomeParagraph = `Rapid development, no fluff.`
const WelcomeButton1 = `GET STARTED`
const WelcomeButton2 = `CONTACT US`

const ServicesTitle = `Services`
const ServicesParagraph = <div>
  <p>We specialise in building bespoke Web and Mobile Development Solutions for your business.</p>
  <p>With over 30 years of integration experience, we can connect your product to any third party system.</p>
  <p>No matter what stage of product development you&apos;re at, we can provide technical advice, mentorship and solutions - so you can concentrate on running your business.</p>
  {/* <p>We're flexible, pragmatic and pride ourselves on balancing economy with quality - we'll maximise your return on investment.</p>
    <p>Longevity - product not project thinking / longterm / it's not over once you go live - just begininning, etc</p>
    <p>It's a partnership - we'll work tightly you and your integration partners, to ensure delivery ...</p> */}
</div>
// We come in, cut through the crap, and get you
// back on track. We take care of your problems, so you can run your business.
const Services1Title = `We understand your problem`
const Services1AltText = `Industry Experience Logo`
const Services1Paragraph = <p>With industry experience in Finance, Medical and Logistics, we’ve
seen it all before.<br />Leverage our experience to help you conquer your mountain.</p>
const Services2Title = `We care about helping you`
const Services2AltText = `Helping You Logo`
const Services2Paragraph = <p>
    We aim to build relationships based on trust and ongoing communication.
    If you want advice or have a simple question - <StyledLinks href={`tel:${CONTACT_US_PHONENUMBER}`}>give us a call</StyledLinks>.
    We&apos;ll always be available.
</p>
const Services3Title = `Business value from day one`
const Services3AltText = `Business Value Logo`
const Services3Paragraph = <p>
    We maximise your return on investment.<br />
    Rapid delivery cycles mean we reduce your time to market,
    allowing you to iterate to the best possible solution.</p>

const TestimonialsTitle = 'Testimonials'
const TestimonialsSummary = ``
const Testimonials = [
  {
    title: `Pascale Helyar-Moray`,
    role: <div><StyledLinks href="https://super-rewards.com" target="_blank" rel="noopener noreferrer">Super Rewards</StyledLinks>, Co-Founder and CEO</div>,
    content: <i>{`Mountain-Pass have been a joy to work with. From the very first meeting until our platform was launched to market, their level of detail, communication and streamlined technology was first class. Scoping documents were very comprehensive, the build was exactly as per timelines, and it was truly a collaborative effort. When building anything new, there is always an element of the unknown - this poses risk to the entrepreneur and the tech team. However at any time, the problem was always workshopped, and presented back to us with a variety of options and recommendations. They kept calm under pressure, and always delivered above and beyond expectations.`}</i>,
    logo: () => <StyledLinks href="https://super-rewards.com" target="_blank" rel="noopener noreferrer"><Images.SuperrewardsLogo /></StyledLinks>
  },
  {
    title: `Matt Peterson`,
    role: <div><StyledLinks href="https://www.regionalaustraliabank.com.au/" target="_blank" rel="noopener noreferrer">Regional Australia Bank</StyledLinks>, Integration &amp; Architecture Manager</div>,
    content: <i>It has been fantastic working with Sam and the team at Mountain Pass on delivering some crucial components of our Australian first Consumer Data Rights data recipient implementation. Their ability to adapt, be pro-active and deliver in a pressure-filled environment was impressive! Super competent with the tech, level headed, helpful, friendly, clever and easy to work with. Looking forward to many more projects with Mountain Pass!</i>,
    logo: () => <StyledLinks href="https://www.regionalaustraliabank.com.au/" target="_blank" rel="noopener noreferrer"><Images.RabLogo /></StyledLinks>
  },
  // {
  //   title: `Emily Hollingum`,
  //   role: <div><StyledLinks href="https://super-rewards.com" target="_blank" rel="noopener noreferrer">Super Rewards</StyledLinks>, Co-Founder</div>,
  //   content: <i>We highly recommend MountainPass. We&apos;ve worked with them for over a year and have found them to be collaborative problem-solvers, quick to respond to any feedback, and easy to work with. They have added tremendous value to our business.</i>,
  //   logo: () => <StyledLinks href="https://super-rewards.com" target="_blank" rel="noopener noreferrer"><Images.SuperrewardsLogo /></StyledLinks>
  // },
  // {
  //   title: `Archie Chen`,
  //   role: <div>Development Manager, Financial Services</div>,
  //   content: <i>I never hesitate to call on Mountain Pass for professional help as I know Sam and the team would always put clients’ interest first and deliver the best outcome for the given situation.
  //       The team is made up of highly motivated individuals whom collectively share a wealth of experience from prior industries.
  //       They deliver high quality work efficiently and their service is highly recommended!</i>,
  //   logo: null
  // },
  // {
  //   title: `Clare Munnelly`,
  //   role: <div><StyledLinks href="https://handleandkate.com" target="_blank" rel="noopener noreferrer">Handle &amp; Kate</StyledLinks>, Executive Director</div>,
  //   content: <i>Mountain Pass has been an invaluable partner
  //       in the progress and maturation of Handle &amp; Kate from start-up to exporter
  //       of hand-made ceramic architectural hardware to retailers internationally.</i>,
  //   logo: Images.HnKLogo
  // },
  {
    title: `Jonathan Despinidic`,
    role: <div><StyledLinks href="https://www.sipora.com.au/" target="_blank" rel="noopener noreferrer">Sipora</StyledLinks>, Co-Founder</div>,
    content: <i>Mountain Pass well and truly over delivered on the original scope and are a valuable development partner to our business.</i>,
    logo: () => <StyledLinks href="https://www.sipora.com.au/" target="_blank" rel="noopener noreferrer"><Images.SiporaLogo /></StyledLinks>
  }
]
const PortfolioTitle = `Portfolio`
const PortfolioParagraph = ``

const Packages = {

  PackagesTitle: `Packages`,
  PackagesSummary: `For new projects we offer a “Jump Start” package - where
     we run a face to face workshop with you, to understand and explore your business
      requirements. From this, we develop an implementation plan, which will outline a 
      proposed build design, with costs and timelines. Although there is a charge for the Jump
      Start package, if you engage us for the subsequent build then we'll be 
      happy to waive it.`,
  Packages1Tier: `Starter`,
  Packages1Price: `Free`,
  Packages1Subtext: `for up to 2 editors and 3 projects`,
  Packages1Items: [
    `3 projects`,
    `30-day version history`,
    `Up to 2 editors`,
    `Unlimited Cloud Storage`,
    `Unlimited Free Viewers`
  ],
  Packages1ButtonText: `Choose Starter`,
  Packages1ButtonSublinkText: `&nbsp;`,
  Packages1MessagePrefill: 'Hi! I\'m interested in the Starter package.',
  Packages2Tier: `Professional`,
  Packages2Price: `$12 per editor/month`,
  Packages2Subtext: `Billed anually or $15 month-to-month`,
  Packages2Items: [
    `Unlimited projects`,
    `Unlimited version history`,
    `Custom file/user permissions`,
    `Invite-only private projects`,
    `Shareable team libraries`
  ],
  Packages2ButtonText: `Choose Professional`,
  Packages2ButtonSublinkText: `Free for students and educators`,
  Packages2MessagePrefill: 'Hi! I\'m interested in the Professional package.',
  Packages3Tier: `Organisation`,
  Packages3Price: `$45 per editor/month`,
  Packages3Subtext: `Annual billing only`,
  Packages3Items: [
    `Org-wide design systems`,
    `Centralised teams`,
    `Private plugins`,
    `Plugin administration`,
    `Shared fonts`,
    `SSO + advanced security`,
    `Design System Analytics`
  ],
  Packages3ButtonText: `Choose Organisation`,
  Packages3ButtonSublinkText: `Contact sales (best for 9+ editors)`,
  Packages3MessagePrefill: 'Hi! I\'m interested in the Organisation package.'
}

const PackagesForm = {
  Questions: [
    {
      'question': 'What is x?',
      'type': 'text'
    },
    {
      'question': 'What is q?',
      'type': 'text'
    },
    {
      'question': 'What is abc?',
      'type': 'multipleChoice',
      'Answers': [
        {
          'Selector': 'A',
          'answer': 'sdasds'
        },
        {
          'Selector': 'B',
          'answer': 'apples'
        },
        {
          'Selector': 'C',
          'answer': 'pears'
        }
      ]
    }
  ]
}

const ContactTitle = `Contact`
const ContactParagraph = <div>Leave us your contact details, and we’ll get in touch.
Alternatively, contact us on {CONTACT_US_PHONENUMBER_LINK}.</div>
const ContactNamePlaceholder = 'Name (required)'
const ContactEmailPlaceholder = 'Email (required)'
const ContactSubjectPlaceholder = 'Subject'
const ContactMessagePlaceholder = 'Message (required)'
const ContactBusinessLocation = () => (
  <React.Fragment>
    <h5>Sydney</h5>
    <p>Level 21, 207 Kent St</p>
    <p>Sydney NSW 2000</p>
  </React.Fragment>)
const ContactBusinessEmail = () => (
  <p className="mt-5">E: <StyledLinks href="mailto:info@mountain-pass.com.au"> info@mountain-pass.com.au</StyledLinks> </p>)
const ContactBusinessMob = () => (
  <p>M: { CONTACT_US_PHONENUMBER_LINK}</p>)
const ContactBusinessRights = () => (
  <React.Fragment><SmallerText className="mt-5">ABN 99 602 813 598 | ACN 602 813 598 </SmallerText>
    <SmallerText> © {new Date().getFullYear()} Mountain Pass Pty Ltd.</SmallerText>
    <SmallerText> All Rights Reserved</SmallerText>
  </React.Fragment>)

const ContactSocialMedia = [
  {
    Icon: faFacebookSquare,
    AltText: `Facebook Icon`
  },
  {
    Icon: faLinkedin,
    AltText: `Linkedin Icon`
  }
]
const ContactMessageButtonText = 'LEAVE A MESSAGE'
const MPLogoAltText = `MountainPass Logo`

const ContactForm = {
  q1_title: 'Name',
  q1: 'How should we address you?',
  q1_placeholder: 'Your Name',
  q2_title: 'Contact',
  q2: 'How can we reach you?',
  q2_placeholder: 'Email or phone number',
  q3_title: 'Message',
  q3: 'What can we help you with?',
  q3_placeholder: 'Your message',
  validation: 'Please do not leave blank.',
  ConfirmSubmission: 'Thank you, we\'ll be in contact soon!'
}

export default {
  MPIcon,
  lang: 'en',
  url: '/en',
  Name,
  HeaderItem0,
  HeaderItem1,
  HeaderItem2,
  HeaderItem3,
  HeaderItem4,
  HeaderItem5,
  WelcomeTitle,
  WelcomeParagraph,
  WelcomeButton1,
  WelcomeButton2,
  ServicesTitle,
  ServicesParagraph,
  Service1Icon,
  Services1Title,
  Services1AltText,
  Services1Paragraph,
  Service2Icon,
  Services2Title,
  Services2AltText,
  Services2Paragraph,
  Services3AltText,
  Service3Icon,
  Services3Title,
  Services3Paragraph,
  TestimonialsTitle,
  TestimonialsSummary,
  Testimonials,
  PortfolioIMG,
  PortfolioTitle,
  PortfolioParagraph,
  Portfolios,
  Packages,
  PackagesForm,
  ContactTitle,
  ContactParagraph,
  ContactNamePlaceholder,
  ContactEmailPlaceholder,
  ContactSubjectPlaceholder,
  ContactMessagePlaceholder,
  ContactSubmitIcon,
  ContactBusinessLocation,
  ContactBusinessEmail,
  ContactBusinessMob,
  ContactBusinessRights,
  ContactSocialMedia,
  ContactMessageButtonText,
  MPLogoAltText,
  ContactForm,
  SiteMeta
}
