import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import posed from 'react-pose'
import styled from 'styled-components'
import Animations from '../Animations'
import { FadedText } from '../form'
import Images from '../image_loading/images'
const { GooglePlayLogo, AppleStoreLogo } = Images

const ContainImage = styled.div`
width: 100%;
height: 100%;
border-radius: 15px;
// border: 1px solid grey;

 img{
    border-radius: 15px;
 }
`

const Overlay = styled.a`
background: linear-gradient(to bottom, var(--gray-dark), var(--gray-dark) 10%, rgba(255, 255, 255, 0));
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
border-radius: 15px !important;
z-index: 10;
color: white !important;
text-shadow: 2px 8px 10px black;
text-decoration: none !important;
padding: 10px;
`

const OverlayText = styled.div`
font-size: 30px !important;
margin-bottom: 30px;
`

const PortfolioItem = styled.div`
text-align: center;
width: 100%;
position: relative;
border-radius: 15px !important;

@media (max-width: 767px) {
    max-width: 266px;
}
&:hover {
    transform: scale(1.05);
}
transition: transform .5s;

&:hover .overlayDisplay {
    opacity: 1;
}
.overlayDisplay {
    opacity: 0;
    transition: opacity .5s;
}
`

const StyledAppIcon = styled(FontAwesomeIcon)`
width: 50px !important;
height: 50px !important;
color: var(--light) !important;
padding: 2px 0px;
margin-right: 20px !important;
`
const StyledAndIcon = styled(FontAwesomeIcon)`
width: 40px !important;
height: 40px !important;
color: var(--light) !important;
padding: 2px 0px;
`

const MobileOnly = styled(({ className = '', ...props }) => <div className={`${className} d-block d-md-none`} {...props} />)`
img { height: 40px; }
`

const GrowingLink = posed.a({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1
  },
  hover: {
    scale: 1.2
  },
  press: {
    scale: 1.1
  }
})

export const Portfolio = (props) => {
  const { Content } = props

  let startOfPortfolio = React.useRef({})

  useEffect(() => {
    Animations('.portfolio', startOfPortfolio.current)
  }, [])

  return (
    <React.Fragment>
      <Container className="py-5" ref={startOfPortfolio}>
        <Row>
          <Col xs={12} md={12} lg={12} className="d-flex flex-column">
            <Col xs={12} md={6}>
              <h1 className="my-3">{Content.PortfolioTitle}</h1>
              {Content.PortfolioParagraph.length > 0 && <FadedText>{Content.PortfolioParagraph}</FadedText>}
            </Col>
          </Col>
        </Row>

        <Row>
          {Content.Portfolios.map((p, i) => {
            const { logo: Logo, linkApple, linkAnd } = p
            return (
              <Col key={i} xs={12} md={4} lg={4} className={`portfolio my-3 d-flex flex-column align-items-center`}>
                <PortfolioItem className="shadow">
                  <Overlay className="overlayDisplay" href={p.link} target="_blank" rel="noopener noreferrer" >
                    <OverlayText>{p.tag}</OverlayText>
                    {p.linkApple &&
                                            <>
                                              <GrowingLink href={p.linkApple} target="_blank" rel="noopener noreferrer">
                                                <StyledAppIcon className="mx-2" icon={faApple} onClick={() => { }} />
                                              </GrowingLink>
                                              <GrowingLink href={p.linkAnd} target="_blank" rel="noopener noreferrer">
                                                <StyledAndIcon className="mx-2" icon={faGooglePlay} onClick={() => { }} />
                                              </GrowingLink>
                                            </>
                    }
                  </Overlay>
                  <ContainImage>
                    {Logo && <Logo />}
                  </ContainImage>
                </PortfolioItem>
                <MobileOnly className="d-flex flex-row pt-2">
                  { linkApple &&
                                    <a href={linkApple} target="_blank" rel="noopener noreferrer">
                                      <GooglePlayLogo className="flex-grow-1 flex-shrink-1" />
                                    </a>
                  }
                  { linkAnd &&
                                    <a href={linkAnd} target="_blank" rel="noopener noreferrer">
                                      <AppleStoreLogo className="flex-grow-1 pl-1 flex-shrink-1" />
                                    </a>
                  }
                </MobileOnly>
              </Col>
            )
          })}
        </Row>
      </Container>
    </React.Fragment>
  )
}

Portfolio.defaultProps = {
  Content: PropTypes.object
}
