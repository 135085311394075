
import React from 'react'
import SiporaLogo from '../image_loading/sipora_logo'
import HnKLogo from '../image_loading/hnk_logo'
import SuperrewardsLogo from '../image_loading/superrewards_logo'
import MyStayWellLogo from '../image_loading/mystaywell_logo'
import MyPatientLogo from '../image_loading/mypatient_logo'
import MyRamsayLogo from '../image_loading/myramsay_logo'
import AddressrLogo from '../image_loading/addressr_logo'
import SafeEntryLogo from '../image_loading/safeentry_logo'
import KneeRomLogo from '../image_loading/kneerom_logo'
import RabLogo from '../image_loading/rab_logo'
import GsiLogo from '../image_loading/gsi_logo'
import GooglePlay from '../../images/google-play-badge.svg'
import AppleStore from '../../images/apple-store-badge.svg'

export default {
  SiporaLogo,
  HnKLogo,
  SuperrewardsLogo,
  MyStayWellLogo,
  MyPatientLogo,
  MyRamsayLogo,
  AddressrLogo,
  SafeEntryLogo,
  KneeRomLogo,
  RabLogo,
  GsiLogo,
  GooglePlayLogo: (props) => <img alt="Google Play Logo" src={GooglePlay} {...props} />,
  AppleStoreLogo: (props) => <img alt="Apple Store Logo" src={AppleStore} {...props} />
}
