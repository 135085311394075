import contentEnglish from './Content.en'
import contentThai from './Content.th'
import SupportedLangs from './SupportedLanguages'

export const DEFAULT_LANG = contentEnglish.lang
export const DEFAULT_LOCALE = contentEnglish

// here is where we configure the supported contents
const allLangs = [
  contentEnglish,
  contentThai
]

export const langs = allLangs.filter(l => ~SupportedLangs.indexOf(l.lang))

export const locales = langs.reduce((prev, curr) => {
  prev[curr.lang] = curr
  return prev
}, {})

export const supportedLanguages = SupportedLangs

export const determineLanguage = (lang = null) => {
  if (~supportedLanguages.indexOf(lang)) return lang
  if (typeof window !== 'undefined' && ~supportedLanguages.indexOf(window.navigator.language)) return window.navigator.language
  lang = DEFAULT_LANG
  return lang
}

export const determineLocalisation = (lang = DEFAULT_LANG) => locales[lang]
